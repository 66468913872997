import { useState } from 'react';
import FuncaoUsuario from '../FuncaoUsuario/FuncaoUsuario';
import NomeUsuario from '../NomeUsuario/NomeUsuario';
import { ReactComponent as Logo } from 'core/assets/images/LOGO.svg'

import Menu from './Menu/Menu';

import './NavMenu.css'
import { Link, useLocation } from 'react-router-dom';

function NavMenu() {

    const [admin, setAdmin] = useState<boolean>(false);
    const location = useLocation();
    //console.log(location)
    return (<>
        <nav className="navbar navbar-light bg-light bg-color">
            <div className="container-fluid">
                <div className="col-12 col-md-8 col-xl-5 col-xxl-4 d-flex justify-content-between">
                    <Menu />
                    <div className="my-auto row text-light">
                        <div className="col-12 col-md-9">
                            <NomeUsuario />
                        </div>
                        <div hidden={admin} className="my-auto d-none d-md-block col-md-3">
                            <FuncaoUsuario emitFuncaoUsuario={
                                (e) => {
                                    !e.trim() ? setAdmin(true) : setAdmin(false)
                                }
                            } />
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-block col-md-4 col-xl-7 col-xxl-8 text-end">
                    <Link to={"/home"}>
                    <Logo className="logo" />
                    </Link>
                </div>
                <div className="d-block d-md-none col-12 text-center">
                    <FuncaoUsuario emitFuncaoUsuario={
                        (e) => {
                            !e.trim() ? setAdmin(true) : setAdmin(false)
                        }
                    } />
                </div>
            </div>
        </nav>
        <div className="row m-2">
                <h4>{location.pathname}</h4>
            </div>
        </>
    );
}

export default NavMenu;