import './ParametroTab.css';

type Props = {
    selected: string;
    cols: string;
}

function ParametroTab({ selected, cols }: Props) {

    return (
        <div className="row py-3" hidden={!(selected === 'PARAMETROS')}>
        </div>
    );

}
export default ParametroTab;