import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { useState } from "react";
import ButtonTab from "../ButtonTab/ButtonTab";
import CadastroTab from "../CadastroTab/CadastroTab";
import ObrigacaoTab from "../ObrigacaoTab/ObrigacaoTab";
import ParametroTab from "../ParametroTab/ParametroTab";
import TabHeader from "../TabHeader/TabHeader";
import './MenuDialog.css';

type Props = {
    open: boolean;
    handleClose: () => void;
}

function MenuDialog({ open, handleClose }: Props) {

    const [selected, setSelected] = useState<string>('CADASTRO');
    const idCadastro = "CADASTRO";
    const idObrigacoes = "OBRIGACOES";
    const idParametros = "PARAMETROS";

    const handleOpenTab = (nome: string) => {
        setSelected(nome);
    };

    const voltarAbaCadastro = () => {
        setTimeout(() => {
            setSelected(idCadastro)
        }, 155)
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
                voltarAbaCadastro();
            }}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                <TabHeader>
                    <ButtonTab
                        onClick={() => handleOpenTab(idCadastro)}
                        selected={selected}
                        id={idCadastro}
                    >
                        Cadastros
                    </ButtonTab>
                    <ButtonTab
                        onClick={() => handleOpenTab(idObrigacoes)}
                        selected={selected}
                        id={idObrigacoes}
                    >
                        Obrigações
                    </ButtonTab>
                    <ButtonTab
                        onClick={() => handleOpenTab(idParametros)}
                        selected={selected}
                        id={idParametros}
                    >
                        Parâmetros
                    </ButtonTab>
                </TabHeader>
            </DialogTitle>

            <DialogContent dividers={true}>
                <CadastroTab selected={selected} cols="col-6 col-md-3 col-lg-2" />
                <ObrigacaoTab selected={selected} cols="col-6 col-sm-4" />
                <ParametroTab selected={selected} cols="col-6 col-sm-4" />
            </DialogContent>

        </Dialog >
    );
}

export default MenuDialog;
