import './MenuButton.css'

type Props = {
    handleOpen: () => void;
}

function MenuButton({ handleOpen }: Props) {

    return (
        <div className="btn" onClick={() => { handleOpen(); }}>
            <i className="fas fa-th color text-light fa-2x"></i>
        </div>
    );

}

export default MenuButton;