import { getAccessTokenDecoded } from "core/utils/auth"
import { useCallback, useEffect, useState } from "react"

import './NomeUsuario.css'

type Props = {
    color?: string;
    className?: string;
    NomeUsuario?: (e: string) => void;
}

function NomeUsuario({ NomeUsuario, className, color }: Props) {

    const [nomeUsuario, setNomeUsuario] = useState<string>('')
    const [buscandoNomeUsuario, setBuscandoNomeUsuario] = useState<boolean>(false)

    const buscarNomeDoUsuarioPeloToken = useCallback(
        () => {
            setBuscandoNomeUsuario(true);
            const accessToken = getAccessTokenDecoded();
            if (accessToken.user_name)
                setNomeUsuario(accessToken.user_name)
            else
                setNomeUsuario('Usuario não identificado')
            setBuscandoNomeUsuario(false)
        },
        [],
    )

    useEffect(() => {
        buscarNomeDoUsuarioPeloToken();
        if (NomeUsuario)
            NomeUsuario(nomeUsuario);
    }, [nomeUsuario, buscarNomeDoUsuarioPeloToken, NomeUsuario])

    return (
        <label className={
            className ? (color ? color + ' ' + className : className) : (color ? color : 'font-white')
        }>
            {
                !buscandoNomeUsuario ? nomeUsuario : <i className="fas fa-spinner fa-pulse"></i>
            }
        </label>
    );
}

export default NomeUsuario;