import ArrowBack from "../ArrowBack/arrowback"
import ButtonEnviar from "../ButtonEnviar/buttonenviar";
type Props = {
    titulo: string;
    children: React.ReactNode;
    enviando?: boolean;
}
const BaseFormCadastro = ({ children, titulo }: Props) => {
    return (
        <div className="meu-base-form">
            <ArrowBack />
            <div className="text-center mt-4"><h3>Cadastro de {titulo}</h3></div>
            {children}
            <ButtonEnviar texto={titulo} />
        </div>
    );
}
export default BaseFormCadastro;