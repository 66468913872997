import { isAllowedByRole, Role } from 'core/utils/auth';
import { useState } from 'react';
import TabBodyButton from '../TabBodyButton/TabBodyButton';
import './CadastroTab.css';

type Props = {
    selected: string;
    cols: string
}

function CadastroTab({ selected, cols }: Props) {
    const [statusBotao, setStatusBotao] = useState(false);

    const allowedRoutes = ['ROLE_ADMIN'] as Role[];

    isAllowedByRole(allowedRoutes) ? setStatusBotao(false) : setStatusBotao(true);
    // useEffect(() => {
    // }, [])
    return (

        <div className="row py-3" hidden={!(selected === 'CADASTRO')}>

            <TabBodyButton to={"/cadastro/orgao"}
                className={cols}
                icon="fas fa-university"
                label="Órgão"
                disabled={statusBotao}
            />


            <TabBodyButton to={""}
                className={cols}
                icon="fas fa-calendar-week"
                label="Feriado"
            />

            <TabBodyButton to={""}
                className={cols}
                icon="fas fa-building"
                label="Empresa"
            />

            <TabBodyButton to={""}
                className={cols}
                icon="fas fa-user-circle"
                label="Usuário"
            />
        </div>
    );

}
export default CadastroTab;