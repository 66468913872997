
type Props = {
    children: any;
}

function TabHeader({ children }: Props) {

    return (
        <div className="row bg-light">
            {children}
        </div>
    );

}

export default TabHeader;