import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { useState } from "react";
import './SenhaDialog.css'


type Props = {
    eventChangeSenha: (senha: string) => void;
    open: boolean;
    voltar: () => void;
    autenticar: () => void;

    // handleClose: () => void;
}

function SenhaDialog({ eventChangeSenha, autenticar, open, voltar }: Props) {

    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

    const [senhavisivel, setSenhavisivel] = useState<boolean>(true);
    const handleClickShowPassword = () => {
        setSenhavisivel(!senhavisivel);
    }

    return (
        <>
            <Dialog
                open={open}
                hideBackdrop={true}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'xs'}
                id="senha"
                fullScreen={fullscreen}
            >
                <div className="py-4 px-2">
                    <DialogTitle id="form-dialog-title">
                        Senha
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Uma senha foi enviada ao seu email. <br /> Verifique-o
                        </DialogContentText>

                        <TextField
                            margin="dense"
                            id="senha"
                            label="Senha"
                            type={senhavisivel ? 'password' : 'text'}
                            autoFocus
                            fullWidth
                            onChange={e => eventChangeSenha(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><i onClick={handleClickShowPassword}
                                    className={(senhavisivel ? 'fas fa-eye' : 'fas fa-eye-slash ') + ' text-secondary'}></i></InputAdornment>,
                            }}
                        />
                    </DialogContent>
                </div>
                <DialogActions>
                    <Button onClick={voltar} color="secondary">
                        <strong>
                            Voltar
                        </strong>
                    </Button>
                    <Button color="primary" onClick={autenticar}>
                        <strong>
                            Entrar
                        </strong>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SenhaDialog;