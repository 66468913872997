
import { useEffect, useState } from 'react';
import drilldown from "highcharts/modules/drilldown.js";

import './ColumnChart.css';
import {  ColumnDrilldownSeries, ColumnSeries } from 'core/utils/Type';

type Props = {
    id: string;
    yAxisTitle: string;
    data: ColumnSeries[];
    drilldownData: ColumnDrilldownSeries[];
    title?: string;
    tooltipHeaderFormat?: string;
    tooltipPointFormat?: string;
}

function ColumnChart({ id, title, yAxisTitle, data, tooltipHeaderFormat, tooltipPointFormat, drilldownData }: Props) {

    const [highcharts] = useState(require('highcharts'));
    require('highcharts/modules/exporting')(highcharts);
    drilldown(highcharts);

    useEffect(() => {
        highcharts.chart(id, {
            chart: {
                type: 'column',
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            credits: {
                enabled: false
              },
            title: {
                text: title
            },
            xAxis: {
                type: 'category'
            },
            legend: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: yAxisTitle
                }
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}%'
                    }
                }
            },
            tooltip: {
                headerFormat: !tooltipHeaderFormat ? '<span style="font-size:11px">{point.name}</span><br>' : tooltipHeaderFormat,
                pointFormat: !tooltipPointFormat ? '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>' : tooltipPointFormat,
            },
            series: data,
            drilldown: {
                series: drilldownData
            }
        });
    }, [
        highcharts,
        id,
        title,
        yAxisTitle,
        data,
        tooltipHeaderFormat,
        tooltipPointFormat,
        drilldownData
    ])
    return (
        <div id={id} style={{ height: '400px' }}></div>
    );
}

export default ColumnChart;