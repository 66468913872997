import { useState } from 'react';
import MenuButton from './MenuButton/MenuButton';
import MenuDialog from './MenuDialog/MenuDialog';
import './Menu.css';

function Menu() {

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <MenuButton handleOpen={handleOpen} />
            <MenuDialog handleClose={handleClose} open={open} />
        </>
    );
}

export default Menu;