import './styles.css'
import { ReactComponent as Logo } from 'core/assets/images/LOGO.svg';
import { Link } from 'react-router-dom';
const NavLogin = () => {
    return (
        <div className="nav-login" tabIndex={1}>
            <Link to="/">
                <Logo className="logo" />
            </Link>
        </div>
    );
}
export default NavLogin;