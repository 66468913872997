import './Empresa.css';

function CadastroEmpresa() {

    return (
        <>
        </>
    );
}

export default CadastroEmpresa;