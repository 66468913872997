import React from 'react';
import './ChartContainer.css';

type Props = {
    cols: string;
    title: string;
    children: React.ReactNode;
}

function ChartContainer({ title, cols, children }: Props) {

    return (
        <div className={cols + " px-2 py-1"}>
            <div className="col-config p-3">
                <p className="chart-title">{title}</p>
                <hr className="chart-title-underline" />
                {children}
            </div>
        </div>
    );
}

export default ChartContainer;