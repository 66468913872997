
import './App.css';
import 'core/pages/Login/Login.css'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'Routes';

function App() {



  return (
    <>
    <Routes />
    <ToastContainer/>
    </>
  );
}

export default App;
