import NavLogin from 'core/components/NavLogin/navlogin';
import './styles.css';
import ArrowBack from 'core/components/ArrowBack/arrowback';
import { useState } from 'react';
import { formContato } from 'core/utils/Type';
import { useForm} from 'react-hook-form';
import { makeRequest } from 'core/utils/request';
import { toast } from 'react-toastify';
// type formNome= {
//     id:string;
//     nome:string;
// }
const Contato = () => {
    const { register, handleSubmit, formState: { errors }, setValue} = useForm<formContato>();
    const [botaoEnviar, setBotaoEnviar] = useState(false);
    // const [assuntos] = useState<formNome[]>([
    //     { id:"teste2", nome: "Problemas com Login" },
    //     { id:"teste3", nome: "Usuário Inativo" },
    //     { id:"teste4", nome: "Sistema não abre" },
    //     { id:"teste5", nome: "O usuário não foi identificado" },
    //     { id:"teste6", nome: "Não recebo o Token de acesso" },
    //     { id:"teste7", nome: "Meu e-mail foi alterado" },
    //     { id:"teste8", nome: "Outros" }]);
    const [texto, setTexto] = useState('');
    const handleChangeTexto = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTexto(event.target.value);
    }
    
    const onSubmit = (data: formContato) => {
        setBotaoEnviar(true);
        const payload = {
            ...data
        }
        console.log(data);

        makeRequest({ url: '/login/suporte', method: 'POST', data: payload })
            .then(() => {
                toast.info('Email de suporte enviado com sucesso!');
                
                
            })
            .catch(() => {
                toast.error('Erro ao Enviar email de Suporte');
            })
            .finally(() => { setBotaoEnviar(false);
                setValue('assunto','');
                setValue('email','');
                setValue('nome','');
                setValue('empresa','');
                setValue('mensagem','');
            })
            
    }

    return (
        <>
            <NavLogin />
                <form className="base-form " onSubmit={handleSubmit(onSubmit)}>
                    <div className="meu-base-form">
                        <ArrowBack />
                        <div className="p-5">

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label className="form-text-contato">Nome</label>
                                    <input {...register("nome", { required: true })} name="nome" type="text" placeholder="Fulano de tal"
                                        className="form-control input-base"></input>
                                        {errors.nome && <span className="text-danger">Este campo é obrigatorio</span>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-text-contato">Email</label>
                                    <input {...register("email", { required: true })} name="email" type="email" placeholder="Funalodetal@gmail.com"
                                        className="form-control input-base"></input>
                                        {errors.email && <span className="text-danger">Este campo é obrigatorio</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <label className="form-text-contato">Empresa</label>
                                <input {...register("empresa", { required: true })} name="empresa" type="text" placeholder="Nome da Empresa ou Filial"
                                    className="form-control input-base"></input>
                                     {errors.empresa && <span className="text-danger">Este campo é obrigatorio</span>}
                            </div>

                            <div className="col-12">
                                <label className="form-text-contato">Assunto</label>
                                {/* <Select {...register("assunto", { required: false })}
                                    name="assunto"
                                    key={`select-${assunto?.nome}`}
                                    value={assunto}
                                    options={assuntos}
                                    getOptionLabel={(option: formNome) => option.nome}
                                    getOptionValue={(option: formNome) => option.id}
                                    className="filter-select-container"
                                    classNamePrefix="estado-select"
                                    placeholder="Selecione um assunto"
                                    inputId="assunto"
                                    onChange={value => handleChangeAssunto(value as formNome)}
                                    isClearable
                                /> */}
                                <select defaultValue="" {...register("assunto", { required: true })} className="form-select" value={texto} onChange={value => handleChangeTexto(value)}>
                                <option value=""></option>
                                <option value="Problemas com Login">Problemas com Login</option>
                                    <option value="Usuário Inativo">Usuário Inativo</option>
                                    <option value="Sistema não abre">Sistema não abre</option>
                                    <option value="O usuário não foi identificadoa">O usuário não foi identificado</option>
                                    <option value="Não recebo o Token de acesso">Não recebo o Token de acesso</option>
                                    <option value="Meu e-mail foi alterado">Meu e-mail foi alterado</option>
                                    <option value="Outros">Outros</option>
                                </select>
                                {errors.assunto && <span className="text-danger">Este campo é obrigatorio</span>}
                            </div>
                            <div className="col-12">
                                <label className="form-text-contato">Mensagem</label>
                                <textarea {...register("mensagem", { required: true })} name="mensagem" className="col-12 form-control meu-text-area" placeholder="Digite aqui sua mensagem" rows={3}></textarea>
                                {errors.mensagem && <span className="text-danger">Este campo é obrigatorio</span>}
                            </div>
                            <div className="col-12 text-end">
                                <button type="submit" disabled={botaoEnviar} className="btn mt-4 border border-1 color-btn">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
        </>
    );
}
export default Contato;