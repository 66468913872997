import { Link } from "react-router-dom";
import './styles.css';

const ArrowBack = () => {
    return (
        <div className="da-espaco">
            <div className="container-arrow">
                <Link to="/home" className="remover-decoration">
                    <div className="container-arrow">
                        <span className="fas fa-arrow-left fa-2x"></span>
                    </div>
                </Link>
            </div>
        </div>
    );
}
export default ArrowBack;