import { Link } from 'react-router-dom';
import './TabBodyButton.css';

type Props = {
    className: string;
    label: any;
    icon: string;
    to: string;
    disabled?: boolean;
    hidden?: boolean;
}


function TabBodyButton({ className, label, icon, to, disabled, hidden }: Props) {


    return (
        <div className={className + ' text-center ' + (disabled?'meu-evento':'')} hidden={hidden} >
            <Link className="remover-decoration"  to={to}>
                <button className="btn rounded-circle btn-shadow btn-menu-tab btn-tab-body p-3 icon-disabled" disabled={disabled}>
                    <i className={icon + " text-light fa-2x"} ></i>
                </button>
            </Link>

            <p className="mt-1 fw-bold">{label}</p>
        </div>
    );
}

export default TabBodyButton;