import NavLogin from "core/components/NavLogin/navlogin";
import { saveSessionData } from "core/utils/auth";
import { makeLogin, makeRequest } from "core/utils/request";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import EmailDialog from "./Email/EmailDialog";
import SenhaDialog from "./Senha/SenhaDialog";
import './Login.css';


type LocationState = {
    from: string;
}
type payload = {
    username: string;
    password: string;
}
function Login() {
    let payload: payload = { username: '', password: '' };
    const [dialogEmail, setDialogEmail] = useState<boolean>(true);
    const [isEmail, setIsEmail] = useState<boolean>(false);
    const [dialogSenha, setDialogSenha] = useState<boolean>(false);
    const [emailArmazenado, setEmailArmazenado] = useState<string>('');
    const [senhaArmazenado, setSenhaArmazenado] = useState<string>('');
    //const [botaoEmail] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { from } = location.state || { from: { pathname: "/home" } };
    const [bloquearBotao, setBloquaerBotao] = useState<boolean>(false);

    const isValidEmail = (valid: boolean) => {
        setIsEmail(valid);
    }
    const selectEmail = () => {
        setBloquaerBotao(true);
        makeRequest({ url: `/login/${emailArmazenado}` })
            .then(response => {
                setDialogEmail(false);
                setDialogSenha(true);
                toast.info('Token Enviado ao E-mail. Verifique-o!');
            })
            .catch((response) => { toast.error(capturarMsgError(response)); console.log(response.response) })
            .finally(() => setBloquaerBotao(false))
    }
    const capturarMsgError = (error: any) => {
        if (error.response && error.response.status === 406) {
            return error.response.data.message
        }
        return "Erro ao Validar E-mail";
    }
    const abrirDialogSenha = () => {
        // setDialogEmail(false);
        // setDialogSenha(true);
        if (isEmail) {
            selectEmail();

        } else {
            toast.error('Email invalido!');
        }
    }

    const abrirDialogEmail = () => {
        setDialogSenha(false);
        setTimeout(() => { setDialogEmail(true); }, 150)

    }
    const autenticar = () => {
        console.log("email", emailArmazenado);
        console.log("senha", senhaArmazenado);
        payload.username = emailArmazenado
        payload.password =  senhaArmazenado.trim();//bcrypt.hashSync(senhaArmazenado, 10);
        //console.log("senha criptografada", payload.password);


        makeLogin(payload)
            .then(response => {

                saveSessionData(response.data);
                history.replace(from);
            })
            .catch(() => { toast.error("Token invalido! Verifique se a espaço no final da senha!") })
    }
    const senha = (senha: string) => {
        setSenhaArmazenado(senha);
    }
    const email = (email: string) => {
        setEmailArmazenado(email);
    }

    return (
        <>
            <NavLogin />
            <EmailDialog
                email={emailArmazenado}
                open={dialogEmail}
                valid={isValidEmail}
                prosseguir={abrirDialogSenha}
                disableButton={bloquearBotao}
                eventChangeEmail={email}
                className={'dialog-border-radius'}
            />
            <SenhaDialog
                voltar={abrirDialogEmail}
                open={dialogSenha}
                autenticar={autenticar}
                eventChangeSenha={senha}
            />
        </>
    );
}

export default Login;