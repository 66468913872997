import "./ButtonTab.css";

type Props = {
    children: any;
    id: string;
    onClick: () => void;
    selected: string;

}

function ButtonTab({ children, selected, id, onClick }: Props) {
    return (
        <div
            onClick={onClick}
            className={selected !== id ? "col-12 col-sm-4 text-center py-3 text-light btn-menu-tab bg-light"
                : "col-12 col-sm-4 text-center py-3 text-light btn-menu-tab-selected"}
        >
            {children}
        </div>
    );
}

export default ButtonTab;