import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
type Props = {
    valor: number;
    velocidadeMili: number;
}

const GaugeChart = ({ valor, velocidadeMili }: Props) => {
    am4core.addLicense("ch-custom-attribution");
    let chart = am4core.create("gaugechart", am4charts.GaugeChart);
    chart.innerRadius = -15;
    

    let axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;

    //let colorSet = new am4core.ColorSet();

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") })
    gradient.stops.push({ color: am4core.color("yellow") })
    gradient.stops.push({ color: am4core.color("green") })

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    setInterval(function () {

        hand.showValue(valor, velocidadeMili, am4core.ease.cubicOut);
    }, 2000)


    return (
            <div id="gaugechart" style={{ width: "100%", height: "300px" }}></div>
    );
}

export default GaugeChart;