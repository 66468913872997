
type Props = {
    texto: string;
    enviando?: boolean;
}
const ButtonEnviar = ({ texto, enviando }: Props) => {
    return (
        <div className="col-12 text-center text-md-end   p-3">
            <button type="submit" disabled={enviando} className="btn mt-4 border border-1 color-btn">
                Cadastrar {texto}
                {
                    enviando ?
                        <>
                            &nbsp;
                            <i className="fas fa-spin fa-circle-notch"></i>
                        </> :
                        ''
                }
            </button>
        </div>
    );
}
export default ButtonEnviar;