
import './CustomProgressBar.css';

type Props = {
    color?: string;
    label: string;
    data: number;
    labelOnComplete?: string;
}

function CustomProgressBar({ color, label, labelOnComplete, data }: Props) {


    const atribuirCor = (value: number) => {
        // 6 cores
        // if (value >= 80 && value < 100) return '#1cc88a';
        // if (value >= 60 && value < 80) return '#36b9cc';
        // if (value >= 40 && value < 60) return '#4e73df';
        // if (value >= 20 && value < 40) return '#f6c23e';
        // if (value < 20) return '#e74a3b'

        // 5 cores
        if (value >= 75 && value < 100) return '#1cc88a';
        if (value >= 50 && value < 75) return '#4e73df';
        if (value >= 25 && value < 50) return '#f6c23e';
        if (value < 25) return '#e74a3b'
        return '#10c200';
    }

    const style = {
        backgroundColor: color ? color : atribuirCor(data),
        width: (data > 100 ? 100 : data) + '%',
        height: '13px'
    }


    return (
        <div className="my-4">
            <label className="label-progressbar label-name">
                {label}
            </label>
            <label className="label-progressbar label-value">
                {data >= 100 ? (labelOnComplete ? labelOnComplete : 'Ok!') : (data + '%')}
            </label>
            <div className="bg-progressbar">
                <div className="bg-progressbar" style={style}>
                </div>
            </div>
        </div>
    );
}

export default CustomProgressBar;