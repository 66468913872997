import PrivateRoute from 'core/components/Routes/PrivateRoute';
import Cadastro from 'core/pages/Cadastro/cadastro';
import Contato from 'core/pages/Contato/contato';
import Error404 from 'core/pages/Error404/Error404';
import Forbidden from 'core/pages/Forbidden/forbidden';
import Home from 'core/pages/Home/Home';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './core/pages/Login/Login';
import history from './core/utils/history';

const Routes = () => (
    <Router history={history}>
        <Switch>
            <Route path="/" exact>
                <Login />
            </Route>
            <Route path="/forbidden">
                <Forbidden />
            </Route>
            {/* <PrivateRoute path="/home">
                <Home />
            </PrivateRoute> */}
            <Route path="/home">
                <Home />
            </Route>
            <Redirect to="/home" path="/cadastro" exact></Redirect>
            <PrivateRoute path="/cadastro">
                <Cadastro />
            </PrivateRoute>
            <Route path="/contato">
                <Contato />
            </Route>
            <Route path="/*">
                <Error404 />
            </Route>
        </Switch>
    </Router>
);
export default Routes;