import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import './DonutChart.css';

type Props = {
    id: string;
    value: string;
    category: string;
    data: object[];
    width?: string;
    height?: string;
}

function DonutChart({ value, category, data, id, width, height }: Props) {
    const style = {
        width: (!height ? '100%' : height),
        height: (!width ? '300px' : width)
    }
    am4core.addLicense("ch-custom-attribution");
    let chart = am4core.create(id, am4charts.PieChart);
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    chart.data = data;
    pieSeries.dataFields.value = value;
    pieSeries.dataFields.category = category;
    chart.innerRadius = am4core.percent(60);
    chart.legend = new am4charts.Legend();
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    return (
        <div id={id} style={style}></div>
    );
}

export default DonutChart;