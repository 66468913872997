
import { getAccessTokenDecoded, Role } from "core/utils/auth";
import { useCallback } from "react";
import { useEffect, useState } from "react";

import "./FuncaoUsuario.css";

type Props = {
    color?: string;
    className?: string;
    emitFuncaoUsuario?: (e: string) => void
}

function FuncaoUsuario({ emitFuncaoUsuario, color, className }: Props) {
    const [funcaoUsuario, setFuncaoUsuario] = useState<string>('');
    const [buscandoFuncaoUsuario, setBuscandoFuncaoUsuario] = useState<boolean>(false);
    const buscarFuncaoUsuario = useCallback(
        () => {
            setBuscandoFuncaoUsuario(true)
            const at = getAccessTokenDecoded();
            if (at.authorities && isAdmin(at.authorities))
                setFuncaoUsuario('Administrador');
            else
                setFuncaoUsuario('')
            setBuscandoFuncaoUsuario(false)
        },
        []
    )
    const isAdmin = (funcoes: Role[]) => {
        return funcoes.includes('ROLE_ADMIN');
    }
    useEffect(() => {
        buscarFuncaoUsuario()
        if (emitFuncaoUsuario)
            emitFuncaoUsuario(funcaoUsuario)

    }, [emitFuncaoUsuario, funcaoUsuario, buscarFuncaoUsuario])
    return (
        <label className={
            className ? (color ? color + ' ' + className : className) : (color ? color : 'font-white')
        }>
            {
                !buscandoFuncaoUsuario ? "(" + funcaoUsuario + ")" : <i className="fas fa-spinner fa-pulse"></i>
            }
        </label>
    );

}

export default FuncaoUsuario;