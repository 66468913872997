import Select from 'react-select';
import BaseFormCadastro from "core/components/BaseFormCadastro/baseformcad";
import NavMenu from "core/components/NavMenu/NavMenu"
import { useState } from "react";
import { formPadraoIdNome } from 'core/utils/Type';



const CadastroOrgao = () => {
    const [estados] = useState<formPadraoIdNome[]>([
        { id: 1, nome: "São Paulo" },
        { id: 2, nome: "Parana" },
        { id: 3, nome: "Santa Catarina" },
        { id: 4, nome: "Pernambuco" }]);
    const [estado, setEstado] = useState<formPadraoIdNome>();

       const handleChangeEstado = (formEstado:formPadraoIdNome) =>{
        setEstado(formEstado);
       }
       const [cidades] = useState<formPadraoIdNome[]>([
        { id: 1, nome: "São Paulo" },
        { id: 2, nome: "Assis" },
        { id: 3, nome: "Pedrinhas Paulista" },
        { id: 4, nome: "Tarumã" }]);
    const [cidade, setCidade] = useState<formPadraoIdNome>();

       const handleChangeCidade = (cidade:formPadraoIdNome) =>{
        setCidade(cidade);
       }
       const [orgaos] = useState<formPadraoIdNome[]>([
        { id: 1, nome: "Federal" },
        { id: 2, nome: "Estadual" },
        { id: 3, nome: "Municipal" }]);
    const [orgao, setOrgao] = useState<formPadraoIdNome>();

       const handleChangeOrgao = (orgao:formPadraoIdNome) =>{
        setOrgao(orgao);
       }
    return (
        <>
        <NavMenu/>
        <BaseFormCadastro titulo={"Orgão"}>
            <div className="p-5">

                <div className="col-12">
                    <label className="form-text-contato">Descrição do Orgão</label>
                    <input name="text" type="text" placeholder="Descrição do Orgão"
                        className="form-control input-base"></input>
                </div>
                <div className="col-12">
                    <label className="form-text-contato">Divisão do Orgão</label>
                    <Select
                        name="orgao"
                        key={`select-${orgao?.id}`}
                        value={orgao}
                        options={orgaos}
                        getOptionLabel={(option: formPadraoIdNome) => option.nome}
                        getOptionValue={(option: formPadraoIdNome) => String(option.id)}
                        className="filter-select-container"
                        classNamePrefix="orgaos-select"
                        placeholder="Selecione o Orgão"
                        inputId="orgao"
                        onChange={value => handleChangeOrgao(value as formPadraoIdNome)}
                        isClearable
                    />
                </div>

                <div className="col-12">
                    <label className="form-text-contato">Estado</label>
                    <Select
                        name="estado"
                        key={`select-${estado?.id}`}
                        value={estado}
                        options={estados}
                        getOptionLabel={(option: formPadraoIdNome) => option.nome}
                        getOptionValue={(option: formPadraoIdNome) => String(option.id)}
                        className="filter-select-container"
                        classNamePrefix="estado-select"
                        placeholder="Selecione o Estado"
                        inputId="estado"
                        onChange={value => handleChangeEstado(value as formPadraoIdNome)}
                        isClearable
                    />
                </div>
                <div className="col-12">
                    <label className="form-text-contato">Município</label>
                    <Select
                        name="cidade"
                        key={`select-${cidade?.id}`}
                        value={cidade}
                        options={cidades}
                        getOptionLabel={(option: formPadraoIdNome) => option.nome}
                        getOptionValue={(option: formPadraoIdNome) => String(option.id)}
                        className="filter-select-container"
                        classNamePrefix="cidade-select"
                        placeholder="Selecione a Cidade"
                        inputId="cidade"
                        onChange={value => handleChangeCidade(value as formPadraoIdNome)}
                        isClearable
                    />
                </div>
            </div>
        </BaseFormCadastro>
        </>
    );
}
export default CadastroOrgao;