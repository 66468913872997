import PrivateRoute from "core/components/Routes/PrivateRoute";
import CadastroEmpresa from "./Empresa/Empresa";
import CadastroOrgao from "./Orgao/orgao";
import CadastroPerfilUsuario from "./PerfilUsuario/PerfilUsuario";
import CadastroProjeto from "./Projeto/Projeto";

import { Switch } from "react-router-dom";

const Cadastro = () => {
    return (
        <Switch>
            <PrivateRoute path="/cadastro/orgao" allowedRoutes={['ROLE_ADMIN']}>
                <CadastroOrgao />
            </PrivateRoute>
            <PrivateRoute path="/cadastro/empresa" allowedRoutes={['ROLE_ADMIN']}>
                <CadastroEmpresa />
            </PrivateRoute>
            <PrivateRoute path="/cadastro/usuario" allowedRoutes={['ROLE_ADMIN']}>
                <CadastroPerfilUsuario />
            </PrivateRoute>
            <PrivateRoute path="/cadastro/projeto" allowedRoutes={['ROLE_ADMIN']}>
                <CadastroProjeto />
            </PrivateRoute>
        </Switch>
    );
}
export default Cadastro;