import './cardgrafic.css';
type Props = {
    titulo: string;
    value: string;
    cardCor: string;
    icone: string;
}
const CardGrafic = ({ titulo, value, cardCor, icone }: Props) => {
    return (
        <div className="card shadow h-100 py-2" style={{ borderLeft: `4px solid ${cardCor}` }}>
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                    <div className="col mr-2 text-start">
                        <div className="text-xs font-weight-bold text-uppercase mb-1 fw-bold" style={{ color: cardCor }}>
                            {titulo}
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-muted text-start fw-bold">
                            {value}
                        </div>
                    </div>
                    <div className="col-auto">
                        <i className={'fas fa-2x text-muted pe-none ' + icone}></i>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardGrafic;