
import './ObrigacaoTab.css';

type Props = {
    selected: string;
    cols: string
}


function ObrigacaoTab({ selected, cols }: Props) {
    return (
        
        <div className="row py-3" hidden={!(selected === 'OBRIGACOES')}>
            
        </div>
        
    );
}
export default ObrigacaoTab;