import CustomProgressBar from "core/components/Charts/CustomProgressBar/CustomProgressBar";
import ChartContainer from "core/components/Charts/ChartContainer/ChartContainer";
import NavMenu from "core/components/NavMenu/NavMenu";
import DonutChart from "core/components/Charts/DonutChart/DonutChart";
import GaugeChart from "core/components/Charts/GaugeChart/gaugechat";
import './Home.css';
import CardGrafic from "core/components/Charts/CardGrafic/cardgrafic";
import ColumnChart from "core/components/Charts/ColumnChart/ColumnChart";
import { ColumnDrilldownSeries, ColumnSeries } from "core/utils/Type";
const Home = () => {

    const data = [
        { label: '1', value: 20 },
        { label: '2', value: 40 },
        { label: '3', value: 60 },
        { label: '4', value: 80 },
        { label: '5', value: 10 },
        { label: '6', value: 30 },
    ]

    const metade = (data.length % 2 === 0) ? Math.trunc(data.length / 2) : Math.trunc(data.length / 2) + 1;

    const valoresIcms = [{
        "country": "Lithuania",
        "litres": 501,
    }, {
        "country": "Czechia",
        "litres": 301,
    }, {
        "country": "Ireland",
        "litres": 201,
    }]

    const coresCards = [
        '#1fc78c',
        '#da4d43',
        '#35b9cd',
        '#efc54f'
    ]
    const iconesCards = [
        'fa-database',
        'fa-exclamation-triangle',
        'fa-truck', 'fa-file-code'
    ]

    const dataCards = [
        { titulo: 'gerado', value: '38' },
        { titulo: 'APROPRIÁVEL', value: '38' },
        { titulo: 'PEDIDO', value: '38' },
        { titulo: 'DEFERIDO', value: '38' },
    ]



    const columnChartSeries: ColumnSeries[] = [

        {
            name: "Browsers",
            colorByPoint: true,
            data: [
                {
                    name: "Chrome",
                    y: 62.74,
                    drilldown: "Chrome"
                },
                {
                    name: "Firefox",
                    y: 10.57,
                    drilldown: "Firefox"
                },
                {
                    name: "Internet Explorer",
                    y: 7.23,
                    drilldown: "Internet Explorer"
                },
                {
                    name: "Safari",
                    y: 5.58,
                    drilldown: "Safari"
                },
                {
                    name: "Edge",
                    y: 4.02,
                    drilldown: "Edge"
                },
                {
                    name: "Opera",
                    y: 1.92,
                    drilldown: "Opera"
                },
                {
                    name: "Other",
                    y: 7.62,
                    drilldown: null
                }
            ]
        }

    ]

    const columnChartDrildownSeries: ColumnDrilldownSeries[] = [

        {
            name: "Chrome",
            id: "Chrome",
            data: [
                [
                    "v65.0",
                    0.1
                ],
                [
                    "v64.0",
                    1.3
                ],
                [
                    "v63.0",
                    53.02
                ],
                [
                    "v62.0",
                    1.4
                ],
                [
                    "v61.0",
                    0.88
                ],
                [
                    "v60.0",
                    0.56
                ],
                [
                    "v59.0",
                    0.45
                ],
                [
                    "v58.0",
                    0.49
                ],
                [
                    "v57.0",
                    0.32
                ],
                [
                    "v56.0",
                    0.29
                ],
                [
                    "v55.0",
                    0.79
                ],
                [
                    "v54.0",
                    0.18
                ],
                [
                    "v51.0",
                    0.13
                ],
                [
                    "v49.0",
                    2.16
                ],
                [
                    "v48.0",
                    0.13
                ],
                [
                    "v47.0",
                    0.11
                ],
                [
                    "v43.0",
                    0.17
                ],
                [
                    "v29.0",
                    0.26
                ]
            ]
        },
        {
            name: "Firefox",
            id: "Firefox",
            data: [
                [
                    "v58.0",
                    1.02
                ],
                [
                    "v57.0",
                    7.36
                ],
                [
                    "v56.0",
                    0.35
                ],
                [
                    "v55.0",
                    0.11
                ],
                [
                    "v54.0",
                    0.1
                ],
                [
                    "v52.0",
                    0.95
                ],
                [
                    "v51.0",
                    0.15
                ],
                [
                    "v50.0",
                    0.1
                ],
                [
                    "v48.0",
                    0.31
                ],
                [
                    "v47.0",
                    0.12
                ]
            ]
        },
        {
            name: "Internet Explorer",
            id: "Internet Explorer",
            data: [
                [
                    "v11.0",
                    6.2
                ],
                [
                    "v10.0",
                    0.29
                ],
                [
                    "v9.0",
                    0.27
                ],
                [
                    "v8.0",
                    0.47
                ]
            ]
        },
        {
            name: "Safari",
            id: "Safari",
            data: [
                [
                    "v11.0",
                    3.39
                ],
                [
                    "v10.1",
                    0.96
                ],
                [
                    "v10.0",
                    0.36
                ],
                [
                    "v9.1",
                    0.54
                ],
                [
                    "v9.0",
                    0.13
                ],
                [
                    "v5.1",
                    0.2
                ]
            ]
        },
        {
            name: "Edge",
            id: "Edge",
            data: [
                [
                    "v16",
                    2.6
                ],
                [
                    "v15",
                    0.92
                ],
                [
                    "v14",
                    0.4
                ],
                [
                    "v13",
                    0.1
                ]
            ]
        },
        {
            name: "Opera",
            id: "Opera",
            data: [
                [
                    "v50.0",
                    0.96
                ],
                [
                    "v49.0",
                    0.82
                ],
                [
                    "v12.1",
                    0.14
                ]
            ]
        }
    ]


    return (
        <>
            <NavMenu />

            <div className="container mt-1 mb-5">

                <div className="row text-center">

                    <p className="title-config">
                        <h1>
                            Projeto e-Credac CAT207 / CAT83
                        </h1>
                    </p>

                    <ChartContainer cols="col-12" title="ICMS Acumulado e-Credac">
                        <div className="row">
                            {
                                dataCards.map(
                                    (e, i) =>
                                        <div className="col-6 px-5 py-2">
                                            <CardGrafic titulo={e.titulo} value={e.value} cardCor={coresCards[i]} icone={iconesCards[i]} key={i} />
                                        </div>
                                )
                            }
                        </div>
                    </ChartContainer>

                    <ChartContainer cols="col-12 col-md-5" title="Valores ICMS">
                        <DonutChart id="donutChartValoresIcms" data={valoresIcms} category="country" value="litres" />
                    </ChartContainer>

                    <ChartContainer cols="col-12 col-md-7" title="Qualidade das Informações">
                        <GaugeChart valor={99} velocidadeMili={5000} />
                    </ChartContainer>

                    <ChartContainer cols="col-12" title="Pontos de Atenção">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                {
                                    data.map(
                                        (e, i) => {
                                            if (i < (metade)) {
                                                return <CustomProgressBar data={e.value} label={e.label} key={i} />
                                            }
                                            return null;
                                        }
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6">
                                {
                                    data.map(
                                        (e, i) => {
                                            if (i >= (metade)) {
                                                return <CustomProgressBar data={e.value} label={e.label} key={i} />
                                            }
                                            return null;
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </ChartContainer>

                    <ChartContainer cols="col-12" title="Volume de Crédito por Ano / Competência">
                        <ColumnChart id="graficoColuna" data={columnChartSeries} drilldownData={columnChartDrildownSeries} yAxisTitle="Valor total do crédito" />
                    </ChartContainer>
                </div>

            </div>
        </>
    )
}
export default Home;