import './PerfilUsuario.css';

function CadastroPerfilUsuario() {
    
    return (
        <>
        </>
    );
}

export default CadastroPerfilUsuario;