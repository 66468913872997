import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import './EmailDialog.css'
import { useTheme } from '@material-ui/core/styles';

type Props = {
    email: string;
    valid: (valid: boolean) => void;
    eventChangeEmail: (email: string) => void;
    open: boolean;
    prosseguir: () => void;
    disableButton: boolean;
    className?: string;
}

function EmailDialog({ valid, email, eventChangeEmail, open, prosseguir, disableButton, className }: Props) {

    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

    const eventValidarEmail = (email: string) => {
        var exclude = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (exclude.test(email)) {
            valid(true);
        }
        else {
            valid(false);
        }
    }

    return (
        <Dialog
            disablePortal={true}
            disableScrollLock={true}
            disableEnforceFocus={true}
            open={open}
            hideBackdrop={true}
            fullWidth={true}
            fullScreen={fullscreen}
            maxWidth={'xs'}
            aria-labelledby="form-dialog-title"
            tabIndex={100}
            id="login"
        >
            <div className="py-4 px-2">
                <DialogTitle className="mb-2 mb-sm-2"
                    id="form-dialog-title"
                >
                    Digite seu E-mail
                </DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        value={email}
                        label="E-mail"
                        type="email"
                        id="email"
                        autoFocus
                        fullWidth
                        onChange={e => {
                            eventChangeEmail(e.target.value);
                            eventValidarEmail(e.target.value);
                        }}
                        className="mb-1 mb-sm-3 text-center"
                    />
                    <div className="mt-1">
                        <span className="span-email">
                            Está com problemas para Conectar?
                            <br />
                            Para entrar em contato Conosco, <Link to="/contato">Clique Aqui!</Link>
                        </span>
                    </div>
                </DialogContent>
            </div>
            <DialogActions>
                <Button
                    onClick={prosseguir}
                    color="primary"
                    disabled={disableButton}
                >
                    <strong>
                        {disableButton ? <i className="fas fa-circle-notch fa-spin">&nbsp;</i> : ''}
                        Prosseguir
                    </strong>
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default EmailDialog;